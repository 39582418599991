//Главная страница
export const biscuit = require("./img/item/Главная/aquarelle5.avif");
export const biscuit_small = require("./img/item/Главная/aquarelle5_small.avif");
export const muss = require("./img/item/Главная/Цветы3.avif");
export const muss_small = require("./img/item/Главная/Цветы3_small.avif");
export const bento = require("./img/item/Главная/bento33.avif");
export const bento_small = require("./img/item/Главная/bento33_small.avif");
export const tarts = require("./img/item/Главная/Фисташка-Клубника.avif");
export const tarts_small = require("./img/item/Главная/Фисташка-Клубника_small.avif");
export const cakes = require("./img/item/Главная/Экзотик.avif");
export const cakes_small = require("./img/item/Главная/Экзотик_small.avif");
export const makarons = require("./img/item/Главная/Лайм-Мята.avif");
export const makarons_small = require("./img/item/Главная/Лайм-Мята_small.avif");
export const zefir = require("./img/item/Главная/Безе_Павлова.avif");
export const zefir_small = require("./img/item/Главная/Безе_Павлова_small.avif");
export const multilevel = require("./img/item/Главная/Свадебный2.avif");
export const multilevel_small = require("./img/item/Главная/Свадебный2_small.avif");
export const ponchiki = require("./img/item/Главная/Красный.avif");
export const ponchiki_small = require("./img/item/Главная/Красный_small.avif");
export const candy = require("./img/item/Главная/Конфеты.avif");
export const candy_small = require("./img/item/Главная/Конфеты_small.avif");
export const action = require("./img/item/Пасха/Куличи_на_главную.avif");
export const action_small = require("./img/item/Пасха/Куличи_на_главную.avif");

//Бисквитные торты
export const rafaello = require("./img/item/Бисквитные/Рафаэлло.avif");
export const fistashka = require("./img/item/Бисквитные/Фисташка-Малина.avif");
export const profitrolli = require("./img/item/Бисквитные/Шоколад-Профитроли.avif");
export const mak = require("./img/item/Бисквитные/Мак-Смородина.avif");
export const laim = require("./img/item/Бисквитные/Лайм-Мята.avif");
export const pecan = require("./img/item/Бисквитные/Морковный_с_орехом_пекан.avif");
export const yabloko = require("./img/item/Бисквитные/Карамельное_яблоко.avif");
export const vishnya = require("./img/item/Бисквитные/Шпинат-Вишня.avif");
export const oreo = require("./img/item/Бисквитные/Орео.avif"); 
export const mokko = require("./img/item/Бисквитные/Ореховое_мокко.avif");  
export const barhat = require("./img/item/Бисквитные/Красный_бархат.avif"); 
export const oreh = require("./img/item/Бисквитные/Морковный_с_грец_орехом.avif"); 
export const beilis = require("./img/item/Бисквитные/Вишня-Бейлис.avif"); 
export const medovik = require("./img/item/Бисквитные/Медовик.avif"); 
export const medovik2 = require("./img/item/Бисквитные/Медовик_сметан крем.avif"); 
export const grusha = require("./img/item/Бисквитные/Карамельная_груша.avif"); 
export const plombir = require("./img/item/Бисквитные/Ягодный_пломбир.avif"); 
export const yogurt_sufle = require("./img/item/Бисквитные/Йогутовое_суфле.avif");
export const klubnich_mohito = require("./img/item/Бисквитные/Клубничное мохито.avif");

//Муссовые торты
export const exotic = require("./img/item/Мусовые торты/Экзотик.avif");
export const roshe = require("./img/item/Мусовые торты/Шоколадный роше.avif");
export const pina = require("./img/item/Мусовые торты/Пина-Колада.avif");
export const med_chernosliv = require("./img/item/Мусовые торты/Мёд-Йогурт-Чернослив.avif");
export const karamel_yabloko = require("./img/item/Мусовые торты/Карамельное яблоко.avif");
export const explosion = require("./img/item/Мусовые торты/Клубничный взрыв.avif");
export const kofe = require("./img/item/Мусовые торты/Кофе-Шоколад.avif");
export const arachis_explosion = require("./img/item/Мусовые торты/Арахисовый взрыв.avif");
export const kardamon = require("./img/item/Мусовые торты/Груша-Карамель-Кардамон.avif");
export const ezhevika = require("./img/item/Мусовые торты/Ежевика Брауни.avif");
export const bob_tonka = require('./img/item/Мусовые торты/Груша Боб Тонка.avif')
export const oblepiha_med_bob_tonka = require('./img/item/Мусовые торты/Облепиха-Мёд-Боб-Тонка.avif');

//Бенто
export const laim_bento = require("./img/item/Бенто/Клубника-Лайм.avif");
export const kokos = require("./img/item/Бенто/Кокос-Вишня.avif");
export const mak_smorodina = require("./img/item/Бенто/Мак-Смородина.avif");
export const latte = require("./img/item/Бенто/Ореховое латте.avif");
export const yabloko_pryan = require("./img/item/Бенто/Пряное яблоко.avif");
export const shokolad_apelsin = require("./img/item/Бенто/Шоколад-Апельсин.avif");

//Многоярусные
export const multi = require("./img/item/Торты на свадьбу и юбилей/Многоярусный торт с Гузель.avif");
export const wedding1 = require("./img/item/Торты на свадьбу и юбилей/Свадебный.avif");
export const children = require("./img/item/Торты на свадьбу и юбилей/Детские сны.avif");
export const provans = require("./img/item/Торты на свадьбу и юбилей/Вид с окна.avif");
export const illusion = require("./img/item/Торты на свадьбу и юбилей/Бабочки.avif");
export const sea = require("./img/item/Торты на свадьбу и юбилей/Ракушки.avif");
export const jubilee1 = require("./img/item/Торты на свадьбу и юбилей/С Днем рождения.avif");
export const wedding2 = require("./img/item/Торты на свадьбу и юбилей/КИ.avif");
export const jubilee2 = require("./img/item/Торты на свадьбу и юбилей/50 лет.avif");
export const jubilee3 = require("./img/item/Торты на свадьбу и юбилей/Парусник.avif");
export const wedding4 = require("./img/item/Торты на свадьбу и юбилей/Свадебный2.avif");

//Тарты
export const implosive_karamel = require("./img/item/Тарты/Взрывная карамель.avif");
export const grusha_tart = require("./img/item/Тарты/Грушевый.avif");
export const kedr = require("./img/item/Тарты/Кедровый орех.avif");
export const klubnika_kokos = require('./img/item/Тарты/Клубника-Кокос.avif');
export const kokos_karamel = require("./img/item/Тарты/Кокос-Карамель.avif");
export const kofe_Beilis = require("./img/item/Тарты/Кофе Бейлис.avif");
export const mak_tart = require("./img/item/Тарты/Маковый.avif");
export const malina = require("./img/item/Тарты/Малиновый.avif");
export const morkov = require("./img/item/Тарты/Морковный.avif");
export const smorodina = require("./img/item/Тарты/Смородиновый взрыв.avif");
export const snikers = require("./img/item/Тарты/Сникерс.avif");
export const sirno_smorodina = require("./img/item/Тарты/Сырно-смородиновый.avif");
export const chernika_morogenoe = require("./img/item/Тарты/Черничное мороженое.avif");
export const clubnika_vanil = require('./img/item/Тарты/Клубника-Ваниль.avif');
export const shokolad_karamel = require("./img/item/Тарты/Шоколадно-карамельный.avif");
export const fistashka_klubnika = require("./img/item/Тарты/Фисташка-Клубника.avif");
export const diabella = require('./img/item/Тарты/Диабелла.avif');
export const tart_rafaello = require("./img/item/Тарты/Рафаэлло.avif");
export const limon_merenga = require("./img/item/Тарты/Лимонный с меренгой.avif");
export const limon = require("./img/item/Тарты/Лимонный.avif");
export const kofe_Brauni = require("./img/item/Тарты/Кофе-Брауни.avif");
export const mango_marakuja = require("./img/item/Тарты/Манго маракуйя.avif");
export const vanil = require("./img/item/Тарты/Ванильный.avif");

//Муссовые пироженные
export const vishnja_shokolad = require("./img/item/Мусовые пироженые/Вишня в шоколаде.avif");
export const klubnika_cakes = require("./img/item/Мусовые пироженые/Клубничное.avif");
export const lemongrass = require("./img/item/Мусовые пироженые/Лимон-Лемонграсс.avif");
export const malina_cakes = require("./img/item/Мусовые пироженые/Малиновое.avif");
export const ekzotic = require("./img/item/Мусовые пироженые/Экзотик.avif");
export const klubnika_implosive_cakes = require("./img/item/Мусовые пироженые/Клубничный взрыв.avif");

//Макаронсы
export const ganash_Beilis = require("./img/item/Макаронсы/Ганаш Бейлис.avif");
export const ganash_Mokko = require("./img/item/Макаронсы/Ганаш Мокко.avif");
export const klubnika_Bazelik = require("./img/item/Макаронсы/Клубника-Базилик.avif");
export const laim_mjata = require("./img/item/Макаронсы/Лайм-Мята.avif");
export const sol_karamel = require("./img/item/Макаронсы/Солёная карамель.avif");
export const fistashka_malina = require("./img/item/Макаронсы/Фисташка-Малина.avif");
export const black_smorodina = require("./img/item/Макаронсы/Чёрная смородина.avif");
export const mango_marakuja_mak = require("./img/item/Макаронсы/Манго-Маракуйя.avif");

//Зефир и Безе
export const ananas_apelsin = require("./img/item/Зефиры, Безе/Ананас-Апельсин-Грецкий орех.avif");
export const vishnja_Tonka = require("./img/item/Зефиры, Безе/Вишня-Тонка.avif");
export const klubnika_zefir = require("./img/item/Зефиры, Безе/Клубничный.avif");
export const kofe_zefir = require('./img/item/Зефиры, Безе/Кофейный.avif');
export const malina_zefir = require("./img/item/Зефиры, Безе/Малиновый.avif");
export const mango_zefir = require("./img/item/Зефиры, Безе/Манго.avif");
export const sliva_zefir = require("./img/item/Зефиры, Безе/Сливовый.avif");
export const Pavlova_beze = require("./img/item/Зефиры, Безе/Безе Павлова.avif");
export const grusha_zefir = require("./img/item/Зефиры, Безе/Грушевый.avif");

//Конфеты
export const rafaello_candy = require("./img/item/Конфеты/Рафаэлло.avif");
export const oreo_candy = require("./img/item/Конфеты/Орео.avif");
export const basilik_candy = require("./img/item/Конфеты/Клубника-Базелик.avif");
export const halva_candy = require("./img/item/Конфеты/Шоколадная халва.avif");
export const fistashka_candy = require("./img/item/Конфеты/Фисташка.avif");
export const funduk_candy = require("./img/item/Конфеты/Фундучное пралине.avif");
export const smorodina_candy = require("./img/item/Конфеты/Чёрная смородина.avif");
export const limon_candy = require("./img/item/Конфеты/Лимон-Мята.avif");
export const finik_candy = require("./img/item/Конфеты/Финик-Орех.avif");
export const drunk_cherry = require("./img/item/Конфеты/Пьяная вишня.avif");
export const oblepiha_appelsin = require("./img/item/Конфеты/Облепиха-Апельсин.avif");

//Тортопончики
export const zavar_ponchik = require("./img/item/Тортопончики/Шоколадный.avif");
export const kokos_ponchik = require("./img/item/Тортопончики/Красный.avif");
export const apelsin_ponchik = require("./img/item/Тортопончики/Серый.avif");
export const jagoda_ponchik = require("./img/item/Тортопончики/Посыпанный.avif");
export const karamel_ponchik = require("./img/item/Тортопончики/Зелёный.avif");
