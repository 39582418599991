import {
  laim_bento,
  kokos,
  mak_smorodina,
  latte,
  yabloko_pryan,
  shokolad_apelsin,
} from "./img_import";

export const bentoList = {
  meta: {
    title: "ДжоКонди | Бенто",
    description: "Бенто торты",
    keywords:
      "клубника, лайм, кокос, вишня, мак, смородина, латте, яблоко, шоколад, апельсин",
  },
  productList: [
    {
      id: "bnt1",
      groupProduct: "Бенто-торт",
      nameProduct: '"Клубника-Лайм"',
      img: laim_bento,
      desc: [
        "Лаймовый мусс с кусочками желе",
        "Маковый бисквит",
        "Ванильный крем с цедрой лайма",
        "Ванильный бисквит",
      ],
      anot: ["Вес торта 900 гр."],
      price: 2000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "bnt2",
      groupProduct: "Бенто-торт",
      nameProduct: '"Кокос-Вишня"',
      img: kokos,
      desc: ["Кокосовый мусс с вишней", "Ванильный бисквит", "Кокосовый крем"],
      anot: ["Вес торта 900 гр."],
      price: 2000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "bnt3",
      groupProduct: "Бенто-торт",
      nameProduct: '"Мак-Смородина"',
      img: mak_smorodina,
      desc: [
        "Лимонный мусс с цедрой",
        "Ягоды смородины",
        "Маковый бисквит",
        "Смородиновый крем",
      ],
      anot: ["Вес торта 900 гр."],
      price: 2000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "bnt4",
      groupProduct: "Бенто-торт",
      nameProduct: '"Ореховое латте"',
      img: latte,
      desc: [
        "Кофейный мусс с кусочками измельченного шоколада",
        "Шоколадный бисквит",
        "Ванильный крем",
        "Ореховый бисквит",
      ],
      anot: ["Вес торта 900 гр."],
      price: 2000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "bnt5",
      groupProduct: "Бенто-торт",
      nameProduct: '"Пряное яблоко"',
      img: yabloko_pryan,
      desc: [
        "Ореховый бисквит",
        "Ванильный крем",
        "Карамелизированные яблоки с корицей",
      ],
      anot: ["Вес торта 900 гр."],
      price: 2000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "bnt6",
      groupProduct: "Бенто-торт",
      nameProduct: '"Шоколад-апельсин"',
      img: shokolad_apelsin,
      desc: [
        "Шоколадный бисквит",
        "Шоколадный крем с цедрой апельсина",
        "Апельсиновый слой с кусочками апельсина",
      ],
      anot: ["Вес торта 900 гр."],
      price: 2000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
};
