import kulich1 from "./img/item/Пасха/Кулич1.avif";
import kulich2 from "./img/item/Пасха/Кулич2.avif";
import kulich3 from "./img/item/Пасха/Кулич3.avif";
import kulich4 from "./img/item/Пасха/Кулич4.avif";
import kulich5 from "./img/item/Пасха/Кулич5.avif";
import kulich6 from "./img/item/Пасха/Кулич6.avif";

export const actionList = {
  meta: {
    title: "ДжоКонди | Пасха",
    description: "Пасха",
    keywords: "акция, Пасха, куличи, предложение",
  },
  productList: [
    {
      id: "kulich1",
      groupProduct: "Кулич",
      nameProduct: "вес 490 гр.",
      img: kulich4,
      price: 1000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "kulich2",
      groupProduct: "Кулич",
      nameProduct: "вес 600 гр.",
      img: kulich3,
      price: 1250,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "kulich3",
      groupProduct: "Кулич",
      nameProduct: "вес 800 гр.",
      img: kulich5,
      price: 1550,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
  action: true,
};
