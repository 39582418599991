//Оформление картинкой на сахарной бумаге
import sugar1 from "../img/item/Оформление тортов/На сахарной бумаге/sugar1.avif";
import sugar2 from "../img/item/Оформление тортов/На сахарной бумаге/sugar2.avif";
import sugar3 from "../img/item/Оформление тортов/На сахарной бумаге/sugar3.avif";
import sugar4 from "../img/item/Оформление тортов/На сахарной бумаге/sugar4.avif";
import sugar5 from "../img/item/Оформление тортов/На сахарной бумаге/sugar5.avif";
import sugar6 from "../img/item/Оформление тортов/На сахарной бумаге/sugar6.avif";
import sugar7 from "../img/item/Оформление тортов/На сахарной бумаге/sugar7.avif";
import sugar8 from "../img/item/Оформление тортов/На сахарной бумаге/sugar8.avif";
import sugar9 from "../img/item/Оформление тортов/На сахарной бумаге/sugar9.avif";
import sugar10 from "../img/item/Оформление тортов/На сахарной бумаге/sugar10.avif";
import sugar11 from "../img/item/Оформление тортов/На сахарной бумаге/sugar11.avif";
import sugar12 from "../img/item/Оформление тортов/На сахарной бумаге/sugar12.avif";
import sugar13 from "../img/item/Оформление тортов/На сахарной бумаге/sugar13.avif";
import sugar14 from "../img/item/Оформление тортов/На сахарной бумаге/sugar14.avif";
import sugar15 from "../img/item/Оформление тортов/На сахарной бумаге/sugar15.avif";
import sugar16 from "../img/item/Оформление тортов/На сахарной бумаге/sugar16.avif";
import sugar17 from "../img/item/Оформление тортов/На сахарной бумаге/sugar17.avif";


export const sugarDecor = [
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 1',
    img: sugar1,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 2',
    img: sugar2,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 3',
    img: sugar3,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 4',
    img: sugar4,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 5',
    img: sugar5,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 6',
    img: sugar6,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 7',
    img: sugar7,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 8',
    img: sugar8,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 9',
    img: sugar9,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 10',
    img: sugar10,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 11',
    img: sugar11,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 12',
    img: sugar12,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 13',
    img: sugar13,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 14',
    img: sugar14,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 15',
    img: sugar15,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 16',
    img: sugar16,
  },
  {
    name: `Картинка на сахарной бумаге`,
    descDecor: 'поз. 17',
    img: sugar17,
  },
];